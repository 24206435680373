import shortid from 'shortid';

const HOST = 'https://g0yu0l4pxj.s3.amazonaws.com/';

const mapFile = file => {
  const { id, type, progress, status, key } = file;
  const location = HOST + key;
  const name = file.file.name

  return {
    id,
    progress,
    type,
    status,
    location,
    file: name,
    size: file.file.size,
    created_at: new Date().toISOString(),
    ext: (/[.]/.exec(name)) ? /[^.]+$/.exec(name) : 'unknown',
  };
}

const cropUnsafeSymbols = (string) => {
  const regexp = /[^0-9a-zA-Z!\-_.*'()\ ]/g;
  return string.replace(regexp, '').replace(/ +/g, ' ');
};

const recursiveDecodeURIComponent = (uriComponent) => {
  try {
    const decodedURIComponent = decodeURIComponent(uriComponent);

    if (decodedURIComponent === uriComponent) {
      return cropUnsafeSymbols(decodedURIComponent);
    }
    return recursiveDecodeURIComponent(decodedURIComponent);
  } catch (e) {
    return cropUnsafeSymbols(uriComponent);
  }
};

class Uploader {

  constructor({ onAddedFiles, onProgressFiles, onLoadedFile, onRemovedFile }) {
    this.files = []
    this.onAddedFiles = onAddedFiles;
    this.onProgressFiles = onProgressFiles;
    this.onLoadedFile = onLoadedFile;
    this.onRemovedFile = onRemovedFile;
  }

  onProgress(id, progress) {
    const fileIndex = this.files.findIndex(file => file.id === id)
    this.files.splice(fileIndex, 1, {
      ...this.files[fileIndex],
      progress,
      status: 'loading',
    })
    if (this.onProgressFiles) this.onProgressFiles(this.getFiles())
  }

  onLoad(id) {
    const fileIndex = this.files.findIndex(file => file.id === id)
    this.files.splice(fileIndex, 1, {
      ...this.files[fileIndex],
      status: 'loaded',
    })
    if (this.onLoadedFile) this.onLoadedFile([this.files[fileIndex]].map(mapFile), id)
  }

  onError(id) {
    const fileIndex = this.files.findIndex(file => file.id === id)
    this.files.splice(fileIndex, 1, {
      ...this.files[fileIndex],
      status: 'error',
    })
    if (this.onLoadedFile) this.onLoadedFile([this.files[fileIndex]].map(mapFile), id)
  }

  removeFile(id) {
    const fileIndex = this.files.findIndex(file => file.id === id);
    if (this.files[fileIndex] && this.files[fileIndex].xhr) {
      this.files[fileIndex].xhr.abort();
    }
    const removedFile = this.files.splice(fileIndex, 1)[0];
    if (this.onRemovedFile) this.onRemovedFile(this.getFiles(), mapFile(removedFile))
  }

  addFiles(files, type) {
    this.files = this.files.concat(
      files.map(
        file => {
          const id = shortid.generate();
          const key = `anh18ug2/${id}/${recursiveDecodeURIComponent(file.name)}`;
          return {
            id,
            key,
            file,
            type,
            xhr: this.getXHR(id, file, key),
            status: 'not loaded',
            progress: 0,
          };
        },
      ),
    );
    if (this.onAddedFiles) this.onAddedFiles(this.getFiles());
  }

  getFiles() {
    return this.files.map(mapFile);
  }

  setFiles(files) {
    this.files = this.files.concat(files.map(file => ({
      ...file,
      file: { name: file.filename, size: file.size },
    })));
  }

  getXHR(id, file, key) {
    const formData = new FormData();
    formData.append('key', key);
    formData.append('AWSAccessKeyId', 'AKIAQ2ZTVN6TGLVWHB72');
    formData.append('acl', 'public-read');
    formData.append('policy', 'CnsgImV4cGlyYXRpb24iOiAiMjAzMy0xMi0wMVQxMjowMDowMC4wMDBaIiwgCiAiY29uZGl0aW9ucyI6IFsgCiB7ImJ1Y2tldCI6ICJnMHl1MGw0cHhqIiB9LCAKIFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICJhbmgxOHVnMi8iXSwgCiBbInN0YXJ0cy13aXRoIiwgIkNvbnRlbnQtVHlwZSIsICIiXSwgCiB7ImFjbCI6ICJwdWJsaWMtcmVhZCIgfQogIF0KIH0K');
    formData.append('signature', 'cg24HI4PkyFpugStJDZLiCXVwFQ=');
    formData.append('file', file);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', HOST);
    xhr.upload.onprogress = e => {
      if (e.lengthComputable) {
        const percent = Math.round(100 * e.loaded / e.total)
        this.onProgress(id, percent)
      }
    };
    xhr.upload.onload = () => {
      this.onLoad(id)
    };
    xhr.upload.onerror = () => {
      this.onError(id)
    };
    xhr.send(formData);
    return xhr;
  }
}

export default Uploader
