import React, { Component } from 'react';
import classnames from 'classnames';

import Modal from '../../../../base_components/Modal';
import Button from '../../../../base_components/Button';

import InputError from '../Form/InputError';

import { Form } from '../Form';
import Icon from '../Icons';
import regexps from '../../../../utils/regexps';

import InputSelect from './../../../../themes/nerdify/components/Form/InputSelect';
import { useLocalesModal } from '../../../../base_components/Locales';

import { DocumentDownloadLink, ServicesImg } from '../../../../themes/nerdify/components/Services';

import './modal.css';

//export default (props) => <Modal {...props} />

export const ModalChangeLocales = () => {
  const { country, countryCode, items, ...rest } = useLocalesModal();

  return (
    <Modal {...rest}>
      <div className="modal__locale-change">
        <h6 className="h6">Hey! Looks like you are from <span className="strong">{country}</span>. You might want<br /> to switch to another version<br /> of the website.</h6>
        <InputSelect
          name='country'
          value={country}
          countryCode={countryCode}
          items={items}
          onChange={rest.onChange}
          isError={false}
        />
        <Button className="button__type6" onClick={rest.onSave}>yes, good idea</Button>
        <Button className="a" onClick={rest.onClose}>No, I’m fine here</Button>
      </div>
    </Modal>
  )
};

export const ModalReportAbuse = (props) => {
  return (
    <Modal {...props}>
      <img className="modal-image modal-image__thumb" src={require(`./img/thumb.svg`)} alt="" />
      <h3 className="h3">Thank you!</h3>
      <h6 className="h6">Report Submitted</h6>
      <Button className="button__type6" onClick={props.onClose}>Got it</Button>
    </Modal>
  )
};

export const ModalApplicationForm = (props) => {
  return (
    <Modal {...props}>
      <img className="modal-image modal-image__thumb" src={require(`./img/thumb.svg`)} alt="" />
      <h3 className="h3">Thank you!</h3>
      <h6 className="h6">An Application Form was submitted successfully</h6>
      <Button className="button__type6" onClick={props.onClose}>Got it</Button>
    </Modal>
  )
};

export const ModalTextBackWithRefSuccess = (props) => {
  return (
    <Modal {...props}>
      <img className="modal-image modal-image__thumb" src={require(`./img/thumb.svg`)} alt="" />
      <h3 className="h3">Thanks for <br /> giving us a&nbsp;try.</h3>
      <h6 className="h6">We'll text you in a second!</h6>
      <p className="p">Meanwhile, let your friends know that now you have your own personal nerd. Make them jealous ;)</p>
      <div className="modal-link">
        <a className="a" target="_blank" rel="noopener noreferrer" href={props.refLink}><strong>Share via Facebook private message</strong></a>
      </div>
      <Button className="button__type6" onClick={props.onClose}>Got it</Button>
    </Modal>
  )
};

export const ModalTextBackWithRefRepeat = (props) => {
  return (
    <Modal {...props}>
      <img className="modal-image modal-image__clock" src={require(`./img/clock.svg`)} alt="" />
      <h3 className="h3">We have already <br /> texted you a&nbsp;few <br /> minutes ago.</h3>
      <h6 className="h6">Please, check your text messages.</h6>
      <p className="p">Meanwhile, let your friends know that now you have your own personal nerd. Make them jealous ;)</p>
      <div className="modal-link">
        <a className="a" target="_blank" rel="noopener noreferrer" href={props.refLink}><strong>Share via Facebook private message</strong></a>
      </div>
      <Button className="button__type6" onClick={props.onClose}>Got it</Button>
    </Modal>
  )
};

export const ModalDocumentPreview = (props) => {
  return (
    <Modal {...props}>
      <div className="modal-preview-img-wrapper">
        {props.previewImg && (
          <ServicesImg className="modal-preview-img" img={props.previewImg} />
        )}
      </div>
      <div className="modal-preview-wrapper">
        <button className="modal-preview-close-btn" onClick={props.onClose}>
          <Icon iconName="close-icon" />
          <Icon iconName="close-icon2" />
        </button>
        {props.previewDoc && (
          <DocumentDownloadLink
            className="button__type13 modal-preview-download-link"
            download={props.previewDoc}
            link={props.previewDoc}
          >Download example</DocumentDownloadLink>
        )}
      </div>
    </Modal>
  )
};

export const ModalTextBackWithEmailSuccess = (props) => {
  const { isOpen, ...rest } = props;

  return (
    <Modal isOpen={isOpen}>
      <h3 className="h3">We'll text you <br /> in a second!</h3>
      <img className="modal-image modal-image__thumb" src={require(`./img/thumb.svg`)} alt="" />

      <ModalTextBackWithEmailForm
        {...rest}
      />
    </Modal>
  )
};

export const ModalTextBackWithEmailRepeat = (props) => {
  const { isOpen, ...rest } = props;

  return (
    <Modal isOpen={isOpen}>
      <h3 className="h3">We have already <br /> texted you a&nbsp;few <br /> minutes ago.</h3>
      <h6 className="h6">Please check your text messages</h6>
      <ModalTextBackWithEmailForm
        {...rest}
      />
    </Modal>
  )
};

const ModalTextBackWithEmailForm = (props) => {
  const { email, isPatching, leadCreated, errors } = props;

  if (leadCreated) {
    return (
      <Form>
        <h6 className="h6">Enter your email to receive <br /> Nerdify discounts and promos</h6>
        <fieldset className="fieldset">
          <div className="input-wrapper">
            <input
              className={classnames("input", { "input__error": errors.email })}
              type="email"
              name="email"
              value={email}
              placeholder="Email"
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="email"
              onChange={props.onChange}
            />
            {errors.email && <InputError>{errors.email}</InputError>}
          </div>
        </fieldset>
        <fieldset className="fieldset">
          <Button className="button__type6" onClick={props.onSubmit} disable={isPatching}>Submit email</Button>
        </fieldset>
        <fieldset className="fieldset">
          <Button className="a" onClick={props.onClose}>
            <strong>No, thank you</strong>
          </Button>
        </fieldset>
      </Form>
    )
  }

  return <Button className="a" onClick={props.onClose}>
    <strong>Close</strong>
  </Button>
}

export class ModalLoginForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      email: '',
      password: '',
      errors: {},
      isHasClick: false,
    }

    this.state = this.initialState;

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
  }

  onReset() {
    this.setState({ ...this.initialState });
  }

  onChangeInput(e) {
    const { name, value } = e.target;
    const { isHasClick } = this.state;

    this.setState({
      [name]: value
    }, () => {
      if (isHasClick) {
        this.validate();
      }
    });
  }

  validate() {
    const { email, password } = this.state;

    const errors = {
      email: !regexps.email(email.trim()) ? 'Required field' : null,
      password: !password.trim().length ? 'Required field' : null,
    };

    this.setState({ errors });

    const isError = Object.keys(errors).some(item => errors[item]);

    return isError;
  }

  onSubmit() {
    const { errors } = this.state;

    this.setState({ isHasClick: true });

    const isError = this.validate();
    if (!isError) {
      this.setState({
        errors: {
          email: 'User not found',
          password: errors.password,
        }
      })
    }
  }

  render() {
    const { email, password, errors } = this.state;

    return (
      <Modal {...this.props}>
        <Form>
          <Icon className="icon-logo" iconName={this.props.logo ? this.props.logo : "logo-glasses"} />
          <h3 className="h3">Hi there, Nerd</h3>

          <fieldset className="fieldset">
            <div className="input-wrapper">
              <input
                className={classnames("input", { "input__error": errors.email })}
                type="email"
                name="email"
                value={email}
                placeholder="Email"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="email"
                onChange={this.onChangeInput}
              />
              {!!errors.email &&
                <InputError>{errors.email}</InputError>
              }
            </div>
          </fieldset>
          <fieldset className="fieldset">
            <div className="input-wrapper">
              <input
                className={classnames("input", { "input__error": errors.password })}
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="off"
                onChange={this.onChangeInput}
              />
              {!!errors.password &&
                <InputError>{errors.password}</InputError>
              }
            </div>
          </fieldset>
          <fieldset className="fieldset">
            <Button className="button__type6" onClick={this.onSubmit}>Log in</Button>
          </fieldset>
          <fieldset className="fieldset">
            <Button className="a" onClick={() => {
              this.onReset();
              this.props.onClose();
            }}>
              <strong>Cancel</strong>
            </Button>
          </fieldset>

        </Form>
      </Modal>
    )
  }
};
