//import React from 'react';

import Landing from './pages/Landing';
import Landing2 from './pages/Landing2';
import Landing3 from './pages/Landing3';
//import Landing8 from "./pages/Landing8";
import AppLp1 from "./pages/AppLp1";
import LandingClp3 from "./pages/LandingClp3";
import LandingNerdifyReviews from "./pages/LandingNerdifyReviews";
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';
import Cookies from './pages/Cookies';
import Refunds from './pages/Refunds';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import HowItWorks2 from './pages/HowItWorks2';
import HowItWorks3 from './pages/HowItWorks3';
import NerdyBot from './pages/NerdyBot';
import Storytelling from './pages/Storytelling';
import CustomServices from './pages/CustomServices';
import HonorCode from './pages/HonorCode';
import ReportAbuse from './pages/ReportAbuse';
import ThankYou from "./pages/ThankYou";

//import Post01 from './pages/Blog/post01.js';
//import Post02 from './pages/Blog/post02.js';
//import Post03 from './pages/Blog/post03.js';
//import Post04 from './pages/Blog/post04.js';
//import Post05 from './pages/Blog/post05.js';
import Post06 from './pages/Blog/post06.js';

import './../../themes/nerdify/assets/css/index.css';

//export const fonts = 'https://fonts.googleapis.com/css?family=Aleo:400,700|Roboto:300,400,500,700&display=swap';

export const viewport = 'width=device-width';

export const favicons = [
  {
    type: 'image/png',
    sizes: false,
    href: '/favicon.png',
  },
];

export const routes = [
  {
    path: '/',
    meta: {
      title:
        'Nerdify | The Best Personal Assistant for Students via text messages',
      description:
        'Our Nerds make sure everything you have written is easy to read, effective, and mistake-free. Try it today!',
      keywords:
        'proofreading, editing, proofreader, editing services, proofreading services, online editing, online proofreading, grammar editor, essay editing, paper editing, academic editing, essay proofreading, article proofreading, paper proofreading, academic proofreading, online proofreader, online paper editor, proofreading online, assignment editor, proofreaders, editing online, editing service, tutoring, tutoring service,',
      robots: 'noindex, nofollow',
    },
    component: Landing2,
    exact: true,
    pageCfg: {
      hasNav: true,
    },
  },
  {
    path: '/cookies',
    meta: {
      title: 'Nerdify | Cookie policy',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Cookies,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/refunds',
    meta: {
      title: 'Nerdify | Refund Policy',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Refunds,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/terms-and-conditions',
    meta: {
      title: 'Nerdify | Terms and Conditions',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Terms,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/privacy-policy',
    meta: {
      title: 'Nerdify | Privacy Policy',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Privacy,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/honor-code',
    meta: {
      title: 'Nerdify | Honor Code',
      description: 'Nerdify | Honor code',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: HonorCode,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/report-abuse',
    meta: {
      title: 'Nerdify | Report abuse',
      description: 'Nerdify | Report Abuse',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: ReportAbuse,
    exact: true,
    pageCfg: {},
  },

  {
    path: '/thenerdybot',
    meta: {
      title: 'Nerdify | Nerdy Bot',
      description: 'Nerdify is a Must Have for Students all over the World',
      keywords: 'nerdy bot, bot, AI bot, facebook bot',
      robots: 'noindex, nofollow',
    },
    component: NerdyBot,
    exact: true,
    pageCfg: {
      hasNav: true,
    },
  },

  // Landing template

  {
    path: '/for-students-lpa1',
    meta: {
      title:
        'Nerdify | The Best Personal Assistant for Students via text messages',
      description:
        'Our Nerds make sure everything you have written is easy to read, effective, and mistake-free. Try it today!',
      keywords:
        'proofreading, editing, proofreader, editing services, proofreading services, online editing, online proofreading, grammar editor, essay editing, paper editing, academic editing, essay proofreading, article proofreading, paper proofreading, academic proofreading, online proofreader, online paper editor, proofreading online, assignment editor, proofreaders, editing online, editing service, tutoring, tutoring service, ',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/for-students-lpa2',
    meta: {
      title:
        'Nerdify | The Best Personal Assistant for Students via text messages',
      description:
        'Our Nerds make sure everything you have written is easy to read, effective, and mistake-free. Try it today!',
      keywords:
        'proofreading, editing, proofreader, editing services, proofreading services, online editing, online proofreading, grammar editor, essay editing, paper editing, academic editing, essay proofreading, article proofreading, paper proofreading, academic proofreading, online proofreader, online paper editor, proofreading online, assignment editor, proofreaders, editing online, editing service, tutoring, tutoring service, ',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/for-students-lpa3',
    meta: {
      title:
        'Nerdify | The Best Personal Assistant for Students via text messages',
      description:
        'Our Nerds make sure everything you have written is easy to read, effective, and mistake-free. Try it today!',
      keywords:
        'proofreading, editing, proofreader, editing services, proofreading services, online editing, online proofreading, grammar editor, essay editing, paper editing, academic editing, essay proofreading, article proofreading, paper proofreading, academic proofreading, online proofreader, online paper editor, proofreading online, assignment editor, proofreaders, editing online, editing service, tutoring, tutoring service, ',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/for-students-lpa4',
    meta: {
      title:
        'Nerdify | The Best Personal Assistant for Students via text messages',
      description:
        'Our Nerds make sure everything you have written is easy to read, effective, and mistake-free. Try it today!',
      keywords:
        'proofreading, editing, proofreader, editing services, proofreading services, online editing, online proofreading, grammar editor, essay editing, paper editing, academic editing, essay proofreading, article proofreading, paper proofreading, academic proofreading, online proofreader, online paper editor, proofreading online, assignment editor, proofreaders, editing online, editing service, tutoring, tutoring service, ',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/for-students-lpa5',
    meta: {
      title:
        'Nerdify | The Best Personal Assistant for Students via text messages',
      description:
        'Our Nerds make sure everything you have written is easy to read, effective, and mistake-free. Try it today!',
      keywords:
        'proofreading, editing, proofreader, editing services, proofreading services, online editing, online proofreading, grammar editor, essay editing, paper editing, academic editing, essay proofreading, article proofreading, paper proofreading, academic proofreading, online proofreader, online paper editor, proofreading online, assignment editor, proofreaders, editing online, editing service, tutoring, tutoring service, ',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {},
  },

  // Landing 3 template, us

  {
    path: '/fb-us-01-lp7',
    meta: {
      title: 'Nerdify | #1 Homework Help for Students via Text Messages',
      description:
        'Nerdify is the most convenient and easiest way to get anything in your college life done 24/7. Send your task to our Nerds. Get help in minutes!',
      keywords:
        'college help service, student help website, student help agency, student job help, help with study, tutor help, schedule help, college student help, university student help',
      robots: 'noindex, nofollow',
    },
    component: Landing3,
    exact: true,
    pageCfg: {
      defaultLocale: 'us',
    },
  },
  {
    path: '/fb-us-02-lp7',
    meta: {
      title: 'Nerdify | #1 Homework Help for Students via Text Messages',
      description:
        'Nerdify is the most convenient and easiest way to get anything in your college life done 24/7. Send your task to our Nerds. Get help in minutes!',
      keywords:
        'college help service, student help website, student help agency, student job help, help with study, tutor help, schedule help, college student help, university student help',
      robots: 'noindex, nofollow',
    },
    component: Landing3,
    exact: true,
    pageCfg: {
      defaultLocale: 'us',
    },
  },
  {
    path: '/fb-us-03-lp7',
    meta: {
      title: 'Nerdify | #1 Homework Help for Students via Text Messages',
      description:
        'Nerdify is the most convenient and easiest way to get anything in your college life done 24/7. Send your task to our Nerds. Get help in minutes!',
      keywords:
        'college help service, student help website, student help agency, student job help, help with study, tutor help, schedule help, college student help, university student help',
      robots: 'noindex, nofollow',
    },
    component: Landing3,
    exact: true,
    pageCfg: {
      defaultLocale: 'us',
    },
  },
  {
    path: '/fb-us-04-lp7',
    meta: {
      title: 'Nerdify |#1 Homework Help for Students via Text Messages',
      description:
        'Nerdify is the most convenient and easiest way to get anything in your college life done 24/7. Send your task to our Nerds. Get help in minutes!',
      keywords:
        'college help service, student help website, student help agency, student job help, help with study, tutor help, schedule help, college student help, university student help',
      robots: 'noindex, nofollow',
    },
    component: Landing3,
    exact: true,
    pageCfg: {
      defaultLocale: 'us',
    },
  },

  {
    path: '/fb-us-01-lp8',
    meta: {
      title: 'Nerdify | #1 Personal Assistant for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'essay, essays, help with essay, proofread my essay, scholarship essay writing, outline help, pay someone, essay writing tips, do my essay, my essay, title help, essay tutoring, essay papers, essay writing tutoring, online essay tutor, essay consultation, university essay, best essay examples',
      robots: 'noindex, nofollow',
    },
    //component: Landing8,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
      //isRedirectToThankYou: true,
    },
  },

  {
    path: '/fb-us-01-lp9',
    meta: {
      title: 'Nerdify | #1 Personal Assistant for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'essay, essays, help with essay, proofread my essay, scholarship essay writing, outline help, pay someone, essay writing tips, do my essay, my essay, title help, essay tutoring, essay papers, essay writing tutoring, online essay tutor, essay consultation, university essay, best essay examples',
      robots: 'noindex, nofollow',
    },
    component: LandingClp3,
    exact: true,
    pageCfg: {
      redirectTo: '/',
      //isRedirectToThankYou: true,
    },
  },

  {
    path: "/app-lp1",
    meta: {
      title: "Nerdify | #1 Personal Assistant for College Students",
      description:
        "Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.",
      keywords:
        "homework, homework help from online tutor, my homework, do my homework, homework help online, homework tutoring, hw help, hw tutors, online homework tutoring, English homework, business homework, pay someone, do your homework, homework tutor, homework management tips",
      robots: "noindex, nofollow",
    },
    component: AppLp1,
    exact: true,
    pageCfg: {
      hasNav: true,
      isRedirectToThankYou: true,
      demoCards: 'main_reviews',
    },
  },

  {
    path: '/nerdify-reviews',
    meta: {
      title:
        'Updated Nerdify Reviews 2024 | reviews of verified users ✅',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'college help service, student help website, tutoring, student help agency, student job help, help with study, tutor help, schedule help, college student help, university student help, nerdify, nerdify reviews, gonerdify, nerdify user reviews, nerdify latest reviews, nerdify reviews 2022, nerdify reviews reddit',
      robots: 'noindex, nofollow',
    },
    component: LandingNerdifyReviews,
    exact: true,
    pageCfg: {
      hasNav: true,
    },
  },


  // HowItWorks2 template

  {
    path: '/fb-lp5-msgpn-us4',
    meta: {
      title:
        'Nerdify | The Best Personal Assistant for Students via text messages',
      description:
        'Our Nerds make sure everything you have written is easy to read, effective, and mistake-free. Try it today!',
      keywords:
        'proofreading, editing, proofreader, editing services, proofreading services, online editing, online proofreading, grammar editor, essay editing, paper editing, academic editing, essay proofreading, article proofreading, paper proofreading, academic proofreading, online proofreader, online paper editor, proofreading online, assignment editor, proofreaders, editing online, editing service, tutoring, tutoring service, ',
      robots: 'noindex, nofollow',
    },
    component: HowItWorks2,
    exact: true,
    pageCfg: {},
  },

  // HowItWorks3 template

  {
    path: '/fb-us-01-lp5',
    meta: {
      title: 'Nerdify | #1 Homework Help for Students via Text Messages',
      description:
        'Nerdify is the most convenient and easiest way to get anything in your college life done 24/7. Send your task to our Nerds. Get help in minutes!',
      keywords:
        'college help service, student help website, student help agency, student job help, help with study, tutor help, schedule help, college student help, university student help',
      robots: 'noindex, nofollow',
    },
    component: HowItWorks3,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/fb-us-02-lp5',
    meta: {
      title: 'Nerdify | #1 Homework Help for Students via Text Messages',
      description:
        'Nerdify is the most convenient and easiest way to get anything in your college life done 24/7. Send your task to our Nerds. Get help in minutes!',
      keywords:
        'college help service, student help website, student help agency, student job help, help with study, tutor help, schedule help, college student help, university student help',
      robots: 'noindex, nofollow',
    },
    component: HowItWorks3,
    exact: true,
    pageCfg: {
      headline: 'Send Your Task & Get Help ASAP',
    },
  },
  {
    path: '/fb-us-03-lp5',
    meta: {
      title: 'Nerdify | #1 Homework Help for Students via Text Messages',
      description:
        'Nerdify is the most convenient and easiest way to get anything in your college life done 24/7. Send your task to our Nerds. Get help in minutes!',
      keywords:
        'college help service, student help website, student help agency, student job help, help with study, tutor help, schedule help, college student help, university student help',
      robots: 'noindex, nofollow',
    },
    component: HowItWorks3,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/fb-us-04-lp5',
    meta: {
      title: 'Nerdify | #1 Homework Help for Students via Text Messages',
      description:
        'Nerdify is the most convenient and easiest way to get anything in your college life done 24/7. Send your task to our Nerds. Get help in minutes!',
      keywords:
        'college help service, student help website, student help agency, student job help, help with study, tutor help, schedule help, college student help, university student help',
      robots: 'noindex, nofollow',
      ogTitle: 'Classes Transferred Online? Get Homework Help From Top Nerds!',
      ogImage:
        'https://go-nerdify.com/img/ogImage/Nerdify_Online_Promocode_for_FB.png',
    },
    component: HowItWorks3,
    exact: true,
    pageCfg: {},
  },

  // Storytelling template

  {
    path: '/fb-lp6-nopn-us4',
    meta: {
      title:
        'Nerdify | The Best Personal Assistant for Students via text messages',
      description:
        'Our Nerds make sure everything you have written is easy to read, effective, and mistake-free. Try it today!',
      keywords:
        'proofreading, editing, proofreader, editing services, proofreading services, online editing, online proofreading, grammar editor, essay editing, paper editing, academic editing, essay proofreading, article proofreading, paper proofreading, academic proofreading, online proofreader, online paper editor, proofreading online, assignment editor, proofreaders, editing online, editing service, tutoring, tutoring service,',
      robots: 'noindex, nofollow',
    },
    component: Storytelling,
    exact: true,
    pageCfg: {
      discount: 'type1',
    },
  },

  // CustomServices

  {
    path: '/micro-services',
    meta: {
      title:
        'Nerdify | The Best Personal Assistant for Students via text messages',
      description:
        'Our Nerds make sure everything you have written is easy to read, effective, and mistake-free. Try it today!',
      keywords:
        'proofreading, editing, proofreader, editing services, proofreading services, online editing, online proofreading, grammar editor, essay editing, paper editing, academic editing, essay proofreading, article proofreading, paper proofreading, academic proofreading, online proofreader, online paper editor, proofreading online, assignment editor, proofreaders, editing online, editing service, tutoring, tutoring service, ',
      robots: 'noindex, nofollow',
    },
    component: CustomServices,
    exact: true,
    pageCfg: {},
  },

  // Blog pages
  {
    path: '/blog/secrets-working-students',
    meta: {
      title: '5 homework secrets that working students never share',
      description: 'Check this article to learn more',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    //component: Post01,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/blog/work-study-be-happy',
    meta: {
      title: '5 unobvious tips to get a perfect life-work balance',
      description: 'Check this article to learn more',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    //component: Post02,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/blog/consequences-of-not-sleeping',
    meta: {
      title: 'Sleep deprivation caused by studies can severely harm your brain',
      description: 'Check this article to learn more',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    //component: Post03,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/blog/tips-to-get-homework-done',
    meta: {
      title: '5 tips to get your homework done on the deadline',
      description: 'Check this article to learn more',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    //component: Post04,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/blog/study-burnout',
    meta: {
      title: 'The untold truth about getting stuck with your homework',
      description: 'Check this article to learn more',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    //component: Post05,
    //exact: true,
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/blog/combine-study-and-work',
    meta: {
      title:
        'Combining work and study might lead to unpredictable consequences, study says',
      description: 'Check this article to learn more',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Post06,
    exact: true,
    pageCfg: {},
  },

  // 301 redirect to Home

  {
    path: '/fb-lp5-msgpn-au',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-msgpn-au2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-msgpn-au3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-msgpn-au4',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-msgpn-au5',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-msgpn-au6',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-nopn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-nopn-us2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-nopn-us3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-nopn-au',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-nopn-au2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-nopn-au3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp6-nopn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp6-nopn-us2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp6-nopn-us3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp6-nopn-au',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp6-nopn-au2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp6-nopn-au3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp6-nopn-au4',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp6-nopn-au5',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp6-nopn-au6',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-new-pn',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-new-mob',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp0-nopn-videoad',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp0-pn-videoad',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp0-nopn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp0-nopn-au',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp0-pn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp-ret-pn',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp-ret-nopn',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp1-pn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp1-nopn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp1-nopn-au',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-us3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-us4',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-us5',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-au2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-au3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-au4',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-au5',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-retar',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-retar2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-retar3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-retar4',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-retar5',
    pageCfg: {
      redirectTo: '/',
    },
  },

  {
    path: '/fb-lp3-nopn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp3-nopn-us2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp3-nopn-us3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-us-prom',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/newchnls-lp1-pn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-gdn',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/bts_stud',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/bts_par',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/bts_pro',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/halloween2018',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/xmas2018',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/blackfriday2018',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/nerdybot',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-working-01',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-int-01',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-regular-01',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-christmas-grinch',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-christmas-mistletoe',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-christmas-nerdy-elves',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-new',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/home',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/for-students',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/for-students-lp1',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/for-students-lp2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/for-students-lp3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/for-students-lp4',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/for-students-lp5',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/for-students-lp6',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp11',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp12',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp13',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp14',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp15',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp16',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp17',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp18',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp19',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/lp2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/for-students-lpa6',
    pageCfg: {
      redirectTo: '/',
    },
  },

  {
    path: '/fb-lp2-nopn-us2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp2-nopn-au',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-us2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-us3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-us4',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-us5',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-au',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-au2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-au3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-au4',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-au5',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-au6',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-retar',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-retar2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-retar3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-retar4',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp4-nopn-retar5',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-msgpn-us',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-msgpn-us2',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/fb-lp5-msgpn-us3',
    pageCfg: {
      redirectTo: '/',
    },
  },
  {
    path: '/maintenance',
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Maintenance,
    pageCfg: {
    }
  },
  {
    path: '/thank-you',
    meta: {
      title: 'Nerdify | #1 Personal Assistant for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'personal assistant for students, someone to edit my paper, proofread my essay, homework help from online tutor, online assignment tutoring, need help writing, concierge service for students, writing tips, nerds online writing help, help to write my paper, pay someone, title help, outline help, essay papers, essay writing tutor, my homework, homework help online, my assignment, college writing, university writing, need help writing, master thesis proofreading, paper checker, essay revision, formatting, need help writing, essay tutoring, college paper, homework tutoring, help with assignments, editing, proofreading',
      robots: 'noindex, nofollow',
    },
    component: ThankYou,
    exact: true,
    pageCfg: {
      backLink: true,
    },
  },
  // 404

  {
    meta: {
      title: 'Nerdify | Error',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: NotFound,
    pageCfg: {},
  },
];
