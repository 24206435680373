import React, { useState, useEffect, useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classnames from 'classnames';

import Item from './Item';

const Cards = ({ source, records, isMobile, children }) => {
  const [active, setActive] = useState(1); //"0" the first element in the middle
  const [direction, setDirection] = useState('');
  const [items, setItems] = useState(records[source] || []);

  // Update `items` when `source` changes
  useEffect(() => {
    setItems(records[source] || []);
  }, [source, records]);

  const moveLeft = useCallback(() => {
    setActive((prevActive) => {
      const newActive = prevActive - 1;
      setDirection('left');
      return newActive < 0 ? items.length - 1 : newActive;
    });
  }, [items.length]);

  const moveRight = useCallback(() => {
    setActive((prevActive) => {
      const newActive = prevActive + 1;
      setDirection('right');
      return newActive % items.length;
    });
  }, [items.length]);

  const generateItems = () => {
    const result = [];
    for (let i = active - 1; i < active + 2; i++) {
      let index = i;
      if (i < 0) {
        index = items.length + i;
      } else if (i >= items.length) {
        index = i % items.length;
      }
      const level = active - i;

      let onClick = () => {};
      if (level < 0) {
        onClick = moveRight;
      } else if (level > 0) {
        onClick = moveLeft;
      }
      const value = items[index];

      result.push(
        <CSSTransition key={`item-${source}-${index}`} timeout={1000}>
          <Item
            value={value}
            level={level}
            onClick={onClick}
            source={source}
          />
        </CSSTransition>
      );
    }
    return result;
  };

  return (
    <div className={classnames('cards cards_0')}>
      <TransitionGroup
        className="activeContainer"
        childFactory={(child) =>
          React.cloneElement(child, { classNames: direction })
        }
      >
        {generateItems()}
      </TransitionGroup>
      {!isMobile && (
        <>
          <div className="cardsArrow" onClick={moveRight} />
          <div
            className="cardsArrow cardsArrowPrev"
            onClick={moveLeft}
          />
        </>
      )}
      {children && children({ active })}
    </div>
  );
};

export default Cards;
