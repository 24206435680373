import React from 'react';

import {
  default as DemoCardsTheme,
  DemoCards1 as DemoCardsTheme1,
} from '../../../../themes/nerdify/components/DemoCards';

import main_reviews from './reviews/main.json';

const records={
  facebook: [
    {
      date: "2 days ago",
      name: "Mark C.",
      photo: "01.jpg",
      photo2x: "01@2x.jpg",
      recommends: true,
      review: "I would definitely recommend Nerdify. Being the procrastinator that I am, I had all the information gathered but didn’t understand how to organize it. I contacted them via an Ad I saw on Instagram and they were happy to help. I had some trouble with the instructions followed, as they were not initially full, but they gladly helped me to understand it all with less than 12 hours from my request. It is perfect. Thank you so much again. You get what you pay for!"
    },
    {
      date: "4 days ago",
      name: "Eric S.",
      photo: "02.jpg",
      "photo2x": "02@2x.jpg",
      recommends: true,
      review: "What an incredible service. Nerdify has gotten me out of two really tight spots! The service they provided was of a really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!"
    },
    {
      date: "1 week ago",
      name: "Tom D.",
      photo: "03.jpg",
      "photo2x": "03@2x.jpg",
      review: "I’ve used Nerdify for 2 years now. Their Nerds are talented and they do everything on time. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.",
      recommends: true,
      reply: "Thank you so much for your kind words, Tom! Can't wait to help you further :)"
    },
    {
      date: "2 weeks ago",
      name: "Reyansh C.",
      photo: "04.jpg",
      "photo2x": "04@2x.jpg",
      review: "I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!",
      recommends: true,
      reply: "Wow, thanks for your feedback. <3"
    },
    {
      date: "Aug 25, 2019",
      name: "Cody P.",
      photo: "05.jpg",
      "photo2x": "05@2x.jpg",
      review: "Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.",
      recommends: true,
      reply: "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back."
    },
    {
      date: "Aug 1, 2019",
      name: "Anita T.",
      photo: "06.jpg",
      "photo2x": "06@2x.jpg",
      recommends: true,
      review: "No matter what the task is, they will get the job done. They are very professional and communicate with their customers. I would highly recommend them."
    },
    {
      date: "Jul 7, 2019",
      name: "Karen G.",
      photo: "07.jpg",
      "photo2x": "07@2x.jpg",
      recommends: true,
      review: "Nerdify has been a savior for several of my tasks. I have been working with Nerds for help for quite a while now and must admit that they provide the best service! Cost-effective and almost instant replies — who doesn't want that?!"
    },
    {
      date: "Jun 8, 2019",
      name: "Pamela T.",
      photo: "08.jpg",
      "photo2x": "08@2x.jpg",
      recommends: true,
      review: "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕"
    },
    {
      date: "Jun 3, 2019",
      name: "Abigail R.",
      photo: "09.jpg",
      "photo2x": "09@2x.jpg",
      review: "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      reply: "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!"
    },
    {
      date: "Apr 19, 2019",
      name: "Mariana F.",
      photo: "10.jpg",
      "photo2x": "10@2x.jpg",
      recommends: true,
      review: "Nerdify is the best thing I ever found out during my college years, they are always there to help you and they never disappoint. Even if I have an issue they go out of their way to make you satisfied. I would recommend to everyone at least once. They are my salvation, lol."
    }
  ],
  trustpilot: [
    {
      date: "3 days ago",
      name: "Julie C.",
      score: "5",
      review: "One of my good friends who has her DNP recommended Nerd to me when I was really choking on my school, job and family loads. Nerd is very professional, honest and the price is right. Their work is quality. You can count on them. Nerd is rounded meaning that they are their to support with any of your needs. I highly recommend Nerd to any that might need their services. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.",
      title: "Professional service"
    },
    {
      date: "1 week ago",
      name: "Nerd Lover",
      score: "5",
      review: "I have used this company twice now. And both times by far exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!",
      title: "5 out of 5 from me!"
    },
    {
      date: "3 weeks ago",
      name: "Yusraa K.",
      score: "5",
      review: "It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!",
      title: "Happy with the results",
      reply: "Thank you for taking the time to share your feedback!"
    },
    {
      date: "2 months ago",
      name: "Randi M.",
      score: "4",
      review: "Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ",
      title: "Not ideal, but nice",
      reply: "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review."
    },
    {
      date: "Aug 5, 2019",
      name: "Wolfy",
      score: "5",
      review: "Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time to find sources for my task but you saved me! I appreciate your service and I have a presentation coming up in a week and I’ll definitely ask you guys for help! This is much appreciated! I recommend this to anyone.",
      title: "Would recommend"
    },
    {
      date: "Jun 20, 2019",
      name: "Kye N.",
      score: "5",
      review: "I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating any concerns that arose. I needed a quick proofreading to one final paper that I didn’t have time for myself and they were super considerate and fast! If you’re on the fence about this company, I would encourage you to give it a try.",
      title: "Simply great!",
      reply: "Thank you so much for your review, Kye! We will do our best to keep you happy! <3"
    },
    {
      date: "May 27, 2019",
      name: "B. Martinez",
      score: "5",
      review: "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available to me 24/7, always a fast response and very willing and eager to help you. I'm glad I found them.",
      title: "Never failed me"
    },
    {
      date: "May 8, 2019",
      name: "Daniel",
      score: "5",
      review: "Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress free is why I want to put my genuine review out there and to let others know about this wonderful service. I would definitely recommend trying them out!",
      title: "Timely and friendly"
    },
    {
      date: "Apr 30, 2019",
      name: "Kalsey C.",
      score: "3",
      review: "I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.",
      title: "Too expensive(",
      reply: "Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)"
    },
    {
      date: "March 25, 2019",
      name: "Anna S.",
      score: "2",
      review: "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: "I was ignored!",
      reply: "Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!"
    },
    {
      date: "Apr 1, 2019",
      name: "Isaac N.",
      score: "5",
      review: "I was skeptical at first when I heard about this website but honestly, I could not be happier. All I had to to was send them a screenshot they immediately responded. Anyone who’s in a bind and needs some help could seriously use this website and benefit from it.",
      title: "My best experience so far"
    }
  ]
};

const records1 = {
  facebook: [
    {
      date: 'Apr 4, 2020',
      name: 'Mark C.',
      photo: '01.jpg',
      photo2x: '01@2x.jpg',
      recommends: true,
      review:
        'I would definitely recommend Nerdify. Being the procrastinator that I am, I had all the information gathered but didn’t understand how to organize it. I contacted them via an Ad I saw on Instagram and they were happy to help. I had some trouble with the instructions followed, as they were not initially full, but they gladly helped me to understand it all with less than 12 hours from my request. It is perfect. Thank you so much again. You get what you pay for!',
    },
    {
      date: 'Apr 1, 2020',
      name: 'Eric S.',
      photo: '02.jpg',
      photo2x: '02@2x.jpg',
      recommends: true,
      review:
        "What an incredible service. Nerdify has gotten me out of two really tight spots! The service they provided was of a really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!",
    },
    {
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve used Nerdify for 2 years now. Their Nerds are talented and they do everything on time. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
      reply:
        "Thank you so much for your kind words, Tom! Can't wait to help you further :)",
    },
    {
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      reply: 'Wow, thanks for your feedback. <3',
    },
    {
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      reply:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    {
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will get the job done. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my tasks. I have been working with Nerds for help for quite a while now and must admit that they provide the best service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    {
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      reply:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    {
      date: 'Jan 10, 2020',
      name: 'Mariana F.',
      photo: '10.jpg',
      photo2x: '10@2x.jpg',
      recommends: true,
      review:
        'Nerdify is the best thing I ever found out during my college years, they are always there to help you and they never disappoint. Even if I have an issue they go out of their way to make you satisfied. I would recommend to everyone at least once. They are my salvation, lol.',
    },
  ],
  trustpilot: [
    {
      date: 'Apr 6, 2020',
      name: 'Julie C.',
      score: '5',
      review:
        'One of my good friends who has her DNP recommended Nerd to me when I was really choking on my school, job and family loads. Nerd is very professional, honest and the price is right. Their work is quality. You can count on them. Nerd is rounded meaning that they are their to support with any of your needs. I highly recommend Nerd to any that might need their services. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.',
      title: 'Professional service',
    },
    {
      date: 'Mar 27, 2020',
      name: 'Nerd Lover',
      score: '5',
      review:
        'I have used this company twice now. And both times by far exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!',
      title: '5 out of 5 from me!',
    },
    {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      score: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      reply: 'Thank you for taking the time to share your feedback!',
    },
    {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      score: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      reply:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      score: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time to find sources for my task but you saved me! I appreciate your service and I have a presentation coming up in a week and I’ll definitely ask you guys for help! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      score: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating any concerns that arose. I needed a quick proofreading to one final paper that I didn’t have time for myself and they were super considerate and fast! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      reply:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      score: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available to me 24/7, always a fast response and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      score: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress free is why I want to put my genuine review out there and to let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      score: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      reply:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      score: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      reply:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    {
      date: 'Dec 5, 2019',
      name: 'Isaac N.',
      score: '5',
      review:
        'I was skeptical at first when I heard about this website but honestly, I could not be happier. All I had to to was send them a screenshot they immediately responded. Anyone who’s in a bind and needs some help could seriously use this website and benefit from it.',
      title: 'My best experience so far',
    },
  ],
};

const DemoCards = (props) => {
  return <DemoCardsTheme title={props.title} records={records} />;
};

const DemoCards1 = (props) => {
  return <DemoCardsTheme1 title={props.title} records={records1} />;
};

const DemoCards2 = (props) => {
  return (
    <DemoCardsTheme
      title={props.title}
      records={props.records ? props.records : records1}
    />
  )
};

export { DemoCards as default, DemoCards1, DemoCards2, main_reviews };
